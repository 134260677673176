import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Update the import here
import './App.scss';
import LandingPage from './components/LandingPage';
import NavBar from './components/NavBar';
import VideoGames from './components/VideoGames';
import GameCritiques from './components/VideoGames/GameCritiques';
import GameMechanics from './components/VideoGames/GameMechanics';
import CuratedRecommendations from './components/VideoGames/CuratedRecommendations';
import StrategicInsights from './components/VideoGames/StrategicInsights';

import GameDevelopment from './components/GameDevelopment';
import CulturalNexus from './components/CulturalNexus';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes> {/* Update the component here */}
          <Route path="/" element={<LandingPage />} />
          
          <Route path="/video-games" element={<VideoGames />} />
          <Route path="/video-games/game-critiques" element={<GameCritiques />} />
          <Route path="/video-games/game-mechanics" element={<GameMechanics />} />
          <Route path="/video-games/curated-recommendations" element={<CuratedRecommendations />} />
          <Route path="/video-games/strategic-insights" element={<StrategicInsights />} />

          <Route path="/game-development" element={<GameDevelopment />} />
          <Route path="/cultural-nexus" element={<CulturalNexus />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
