import React from 'react';
import './styles.scss';

const StrategicInsights = () => {
  return (
    <div className="strategic-insights">
      <h2>Strategic Insights</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla...</p>
    </div>
  );
};

export default StrategicInsights;
