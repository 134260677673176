import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const VideoGames = () => {
  return (
    <div className="master-page video-games">
      <h1>Video Games</h1>
      <div className="subcategories">
        <Link to="/video-games/game-critiques" className="subcategory">Game Critiques</Link>
        <Link to="/video-games/game-mechanics" className="subcategory">Game Mechanics</Link>
        <Link to="/video-games/curated-recommendations" className="subcategory">Curated Recommendations</Link>
        <Link to="/video-games/strategic-insights" className="subcategory">Strategic Insights</Link>
      </div>
    </div>
  );
};

export default VideoGames;
