import React from 'react';
import './styles.scss';

const GameCritiques = () => {
  return (
    <div className="game-critiques">
      <h2>Game Critiques</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla...</p>
    </div>
  );
};

export default GameCritiques;
