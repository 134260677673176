import React from 'react';
import './styles.scss';

const GameMechanics = () => {
  return (
    <div className="game-mechanics">
      <h2>Game Mechanics</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla...</p>
    </div>
  );
};

export default GameMechanics;
