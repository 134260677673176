import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ThemeToggle from '../ThemeToggle';
import './styles.scss';

const NAV_ITEMS = [
  { name: 'Video Games', path: '/video-games' },
  { name: 'Game Development', path: '/game-development' },
  { name: 'Cultural Nexus', path: '/cultural-nexus' },
];

const NavBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);  // toggles the isOpen value between true and false
  }

  return (
    <nav>
  <NavLink to="/" className="logo" exact>ALTPSYCHE</NavLink>
  <div className="nav-content">
    <div className={`nav-items ${isOpen ? 'open' : ''}`}>
      {NAV_ITEMS.map(({ name, path }) => (
       <NavLink 
       key={path} 
       to={path}
       className={location.pathname === path ? 'active' : ''}
       onClick={handleToggle}  // close the menu when a link is clicked
     >
       <span>{name}</span>
     </NavLink>
     
      ))}
    </div>
  </div>
  <button className="hamburger-menu" onClick={handleToggle}>
    ☰
  </button>
  <div className="theme-toggle-btn">
    <ThemeToggle />
  </div>
</nav>
  );
};

export default NavBar;
