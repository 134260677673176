import React from 'react';
import './styles.scss';

const CuratedRecommendations = () => {
  return (
    <div className="curated-recommendations">
      <h2>Curated Recommendations</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla...</p>
    </div>
  );
};

export default CuratedRecommendations;
